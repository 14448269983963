import { useMutation } from 'react-query';
import { useAuth } from '../../hooks/useAuth';
import { updateEventRegistrationPayment } from '../../services/events';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

export const useUpdateEventPaymentMutation = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return useMutation(
    'UpdateEventPayment',
    async ({ paymentIntentId, eventId, paymentIntentClientSecret }) => {
      return updateEventRegistrationPayment({
        data: {
          eventId,
          paymentIntentId,
          paymentIntentClientSecret,
        },
        token: user?.token,
      });
    },
    {
      onError: (err) => {
        message.error('Something went wrong. Please try later!');
        navigate(-2);
      },
    },
  );
};
