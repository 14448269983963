import { ArrowLeftOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import EventPayment from '../payment/EventPayment';
import StripePaymentWrapper from '../payment/StripePaymentWrapper';

function EventPaymentOption() {
  const navigate = useNavigate();

  const location = useLocation();

  const { id } = useParams();

  useEffect(() => {
    console.log('location.state===>>>', location.state);
    if (!location.state) {
      message.error('Something get wrong please try again!');
      navigate('/event-detail/' + id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <div>
      <div className="main-container my-[100px] ">
        <div className="container">
          <div className=" px-[15%]">
            <div className="flex mb-[50px]">
              <div className="w-[50%]">
                <div className="flex items-center ">
                  <div
                    className="border-[1px] border-solid border-gray rounded-full w-8 h-8 flex justify-center items-center  cursor-pointer"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowLeftOutlined className="text-[24px]" />
                  </div>
                  <div
                    className="ml-4 font-bold text-[18px] underline-[blue] cursor-pointer"
                    onClick={() => navigate(-1)}
                  >
                    {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <div className="text-[blue] underline">BACK</div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Radio.Group size="large">
              <Radio.Button value="a">Online Banking</Radio.Button>
              <Radio.Button value="b">Credit / Debit Card</Radio.Button>
            </Radio.Group> */}

            <StripePaymentWrapper checkoutIntentData={location.state}>
              <EventPayment />
            </StripePaymentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventPaymentOption;
