import React from 'react';
import { useNavigate } from 'react-router-dom';

function AllCourses() {
  const navigate = useNavigate();
  return (
    <div className="main-container pb-[80px]">
      <div className="container">
        <div className="tablet:px-[15px] ">
          <div className="relative z-0">
            <div
              className="view-all-courses-section-background-image"
              style={{ backgroundImage: "url('./images/workflow2.png')" }}
            ></div>
            <div className="view-all-courses-section-content">
              <h1>View from our vast list of courses</h1>
              <div className="float-right">
                Each event curated by experts in the field.
              </div>
            </div>
            <div className="absolute bottom-2 right-2 z-50">
              <button
                className="button-color search-login-button border-none mt-10 text-white h-[35px] bg-[#165887] rounded-3xl flex items-center gap-2  px-9 py-[25px] cursor-pointer hover:bg-[rgb(68,95,216)] duration-300"
                onClick={() => {
                  navigate('/modules');
                }}
              >
                View all Modules
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllCourses;
