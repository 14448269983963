import React, { useState } from 'react';
import bar_code from './bar_code.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, message, Upload } from 'antd';
import MultiFileUploader from '../home/shared/MultiFileUploader/MultiFileUploader';

const EventPayment = () => {
  const [materialDocuments, setMaterialDocument] = useState([]);
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const eventId = useParams().id;

  const handleSubmit = () => {
    if (materialDocuments.length < 1) {
      message.error('Please upload your receipt');
      return;
    }
    navigate(`/events/${eventId}/payment/callback`);
  };
  return (
    <div className="mt-8">
      <h1 className="text-center mb-5">Payment Method</h1>
      <Form id="payment-form" onFinish={handleSubmit} layout="vertical">
        <div className="flex justify-center">
          <img alt="" src={bar_code} width={150} />
        </div>
        <Form.Item
          className="mt-5"
          name={'file'}
          label="Upload your receipt here"
        >
          <MultiFileUploader
            files={materialDocuments}
            setFiles={setMaterialDocument}
            type={'pdf'}
            edit={true}
          />
        </Form.Item>
        <div className="mt-4 flex justify-end">
          <button
            type="submit"
            onClick={() =>
              fileName && navigate(`/events/${eventId}/payment/callback`)
            }
            className="button-color border-none  text-white h-[40px] bg-[#165887] flex items-center gap-2  px-6 py-2 rounded cursor-pointer hover:bg-[rgb(68,95,216)] duration-300"
          >
            <span>Submit</span>
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EventPayment;
