import React, { useEffect, useState } from 'react';
import Events from './Events';
import CreateEvent from './create-event/create-event';
import AdditionalEventDetails from './create-event/additional-event-detail';
import { useQuery } from 'react-query';
import { useAuth } from '../../hooks/useAuth';
import { getAllEvents } from '../../services/padiMedical';
import { Spin } from 'antd';
import EventTickets from './event-tickets/EventTickets';
import { useNavigate, useSearchParams } from 'react-router-dom';

const tabs = ['All', 'Available', 'Past'];
const defaultTab = 'Available';

function PadiMedicalTabs() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(defaultTab);
  const [eventId, setEventId] = useState();

  const [searchParams] = useSearchParams();
  const urlTab = searchParams.get('tab');

  const { data, isLoading } = useQuery('padimedical', () => {
    return getAllEvents({ token: user?.token });
  });
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(`/dashboard?tab=${tab}`);
  };

  const allEvents = data?.data?.all;
  const availableEvents = data?.data?.available;
  const pastEvents = data?.data?.past;

  useEffect(() => {
    if (urlTab) setActiveTab(urlTab);
  }, [urlTab]);
  console.log(eventId);
  return (
    <div>
      {
        <div
          className="bg-orange-700 h-[35px] text-white px-4 flex justify-center items-center w-[200px] float-right rounded-md cursor-pointer create-an-event"
          onClick={() => {
            setActiveTab('event-creation');
            navigate(`/dashboard?tab=event-creation`);
          }}
        >
          Create a PadiMedical
        </div>
      }
      <div className="tabs flex">
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`tab px-3 bg-white border-[1px] border-[#eef1fd] border-solid h-[35px] cursor-pointer flex items-center justify-center min-w-[80px] ${
              tab === activeTab ? 'event-active-tab' : ''
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <Spin spinning={isLoading}>
        <div className="tab-content">
          {[
            ...tabs,
            ...['event-creation', 'additional-padimedical-detail'],
          ].map((tab) => (
            <div
              key={tab}
              className={`tab-pane  main-event-tab-padding p-4 bg-[#FFFFFF] ${
                tab === activeTab ? ' ' : 'hidden'
              }`}
            >
              {/* Content for each tab */}
              {tab === 'All' && <Events events={allEvents} />}
              {tab === 'Available' && <Events events={availableEvents} />}
              {tab === 'Past' && <Events events={pastEvents} />}
              {tab === 'Event-Tickets' && <EventTickets />}
              {tab === 'event-creation' && (
                <CreateEvent
                  setActiveTab={setActiveTab}
                  setEventId={setEventId}
                />
              )}
              {tab === 'additional-padimedical-detail' && (
                <AdditionalEventDetails
                  eventId={eventId}
                  setActiveTab={setActiveTab}
                />
              )}
            </div>
          ))}
        </div>
      </Spin>
    </div>
  );
}

export default PadiMedicalTabs;
