import React from 'react';
import { useQuery } from 'react-query';
import { allPaymentTickets } from '../../../services/payment';
import { useAuth } from '../../../hooks/useAuth';
import Ticket from './Ticket';
import { Spin } from 'antd';

function EventTickets() {
  const { user } = useAuth();
  const { data, isLoading } = useQuery('allPaymentTickets', () => {
    return allPaymentTickets({ token: user?.token });
  });

  const eventDetails = data?.paymentDetails;

  const colors = ['#C8FCFF', '#8CCCD3', '#F8BCC7'];

  const getColorByIndex = (index) => {
    return colors[index % colors.length];
  };
  return (
    <Spin spinning={isLoading}>
      <h1>EventTickets</h1>
      {eventDetails?.map((detailedData, index) => {
        return (
          <Ticket
            key={detailedData?.id}
            detailedData={detailedData}
            backgroundColor={getColorByIndex(index)}
          />
        );
      })}
    </Spin>
  );
}

export default EventTickets;
