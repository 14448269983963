import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { paymentVerification } from '../../../services/payment';
import EventVerificationCard from './EventVerificationCard';
import { Spin, message } from 'antd';

function EventVerification() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const { data, isloading } = useQuery(['paymentVerification', id], () => {
    return paymentVerification({ token: user?.token, id });
  });

  if (!id) {
    message.error('Id not found!');
    navigate('/');
  }

  if (isloading) {
    <Spin spinning={isloading}>
      <div className="h-[70vh]"></div>
    </Spin>;
  }

  if (data?.paymentDetails)
    return (
      <>
        <div>
          <EventVerificationCard detailedData={data?.paymentDetails} />
        </div>
      </>
    );
}

export default EventVerification;
