import React from 'react';
import QRCode from 'react-qr-code';
import {
  UserOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  PushpinOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import moment from 'moment';

function Ticket({ detailedData, backgroundColor }) {
  return (
    <div className="w-full flex justify-center mb-4">
      <div
        className="border-[1px] border-solid border-gray rounded-xl max-w-[400px] "
        style={{ backgroundColor: backgroundColor }}
      >
        <div className="p-4">
          <div className="flex flex-col items-center">
            <QRCode
              size={50}
              style={{
                height: 'auto',
                maxWidth: '120px',
                width: '120px',
              }}
              value={
                process.env.REACT_APP_FRONTEND_URL +
                '/event-verification/?id=' +
                detailedData?.id
                // '?name=' +
                // detailedData?.user?.firstName +
                // detailedData?.user?.lastName +
                // '&email=' +
                // detailedData?.user?.email +
                // '&eventName=' +
                // detailedData?.event?.title +
                // '&registrationDate=' +
                // detailedData?.event?.createdAt +
                // '&practicingNumber=' +
                // detailedData?.EventRegistration?.practicingNumber
              }
            />
          </div>

          <br />
          <h2 className="text-center">{detailedData?.event?.title}</h2>
          <div className="text-left">
            <div className="flex mt-4">
              <div className="rounded-full p-[1px] border-[1px] border-solid border-gray w-6 h-6">
                <UserOutlined className="text-[18px] ml-[1px]" />
              </div>
              <div className="ml-2 ">
                {detailedData?.EventRegistration?.fullName}
              </div>
            </div>
            <div className="flex mt-4">
              <ClockCircleOutlined className="text-[20px]" />
              <div className="ml-2">
                {moment(detailedData?.event?.scheduleStart).format('h:mma')}
                &nbsp;&nbsp;-&nbsp;&nbsp;
                {moment(detailedData?.event?.scheduleEnd).format('h:mma')}
              </div>
            </div>
            <div className="flex mt-4">
              <CalendarOutlined className="text-[20px]" />
              <div className="ml-2">
                {moment(detailedData?.event?.scheduleStart).format(
                  'D MMMM, YYYY',
                )}
                &nbsp;&nbsp;-&nbsp;&nbsp;
                {moment(detailedData?.event?.scheduleEnd).format(
                  'D MMMM, YYYY',
                )}
              </div>
            </div>
            <div className="flex mt-4">
              <PushpinOutlined className="text-[20px]" />
              <div className="ml-2">{detailedData?.event?.location}</div>
            </div>
            {detailedData?.EventRegistration?.registrationFor ===
              'forOther' && (
              <div>
                <div className="flex mt-4">
                  Registered by :
                  <div className="rounded-full p-[1px] border-[1px] border-solid border-gray w-6 h-6 ml-4">
                    <UserOutlined className="text-[18px] ml-[1px]" />
                  </div>
                  <div className="ml-2 ">
                    {detailedData?.user?.firstName}
                    {' ' + detailedData?.user?.lastName}
                  </div>
                </div>
              </div>
            )}
            <br />
            <br />
          </div>
        </div>
        <div
          className="w-full border-[1px] border-solid border-[#9c9c9c] bg-[#f0f0f0] rounded-xl flex"
          style={{ borderBottom: 'none' }}
        >
          <div className="w-[50%] p-2 py-4 flex items-center">
            <MailOutlined className="text-[20px]" />
            <div className="text-[10px] ml-2">
              <div>anis@longemend.com</div>
              <div>elyia@longemend.com</div>
            </div>
          </div>
          <div
            className="w-[50%] border-l h-full p-2 py-4  flex items-center"
            style={{
              borderLeft: '1px solid #9c9c9c',
            }}
          >
            <PhoneOutlined className="text-[20px]" />
            <div className="text-[10px] ml-2">
              <div>+60389592593 (Anis/Elyia)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ticket;
