import { useMutation } from 'react-query';
import { createCheckoutIntent } from '../../services/payment';
import { useAuth } from '../../hooks/useAuth';

export const useCreateCheckoutIntentMutation = () => {
  const { user } = useAuth();

  return useMutation('checkoutIntent', async (data) => {
    return createCheckoutIntent({
      data,
      token: user?.token,
    });
  });
};
