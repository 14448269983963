import React from 'react';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import moment from 'moment';

function EventVerificationCard({ detailedData, backgroundColor }) {
  return (
    <div className="w-full flex justify-center mb-20 mt-20">
      <div
        className="border-[1px] border-solid border-gray rounded-3xl max-w-[350px] "
        style={{ backgroundColor: backgroundColor }}
      >
        <div className="p-4">
          <br />
          <h3>
            Title Workshop:{' '}
            <span className="text-gray-600">{detailedData?.event?.title}</span>
          </h3>
          <div className="flex ">
            <h3>
              Date:
              <span className="text-gray-600 ml-2">
                {moment(detailedData?.event?.scheduleStart).format(
                  'D MMMM, YYYY h:mma',
                )}
                &nbsp;&nbsp;-&nbsp;&nbsp;
                {moment(detailedData?.event?.scheduleEnd).format(
                  'D MMMM, YYYY h:mma',
                )}
              </span>
            </h3>
          </div>
          <div className="flex">
            <h3>
              Location:
              <span className="text-gray-600 ml-2">
                {detailedData?.event?.location}
              </span>
            </h3>
          </div>
          <hr />
          <div className="flex mt-8">
            <h3>
              Name:
              <span className="text-gray-600 ml-2">
                {detailedData?.EventRegistration?.fullName}
              </span>
            </h3>
          </div>
          <div className="flex">
            <h3>
              Email:
              <span className="text-gray-600 ml-2">
                {detailedData?.EventRegistration?.email}
              </span>
            </h3>
          </div>
          <div className="flex">
            <h3>
              Practicing Number:
              <span className="text-gray-600 ml-2">
                {detailedData?.EventRegistration?.practicingNumber}
              </span>
            </h3>
          </div>
        </div>
        <div
          className="w-full border-[1px] border-solid border-[#9c9c9c] bg-[#f0f0f0] rounded-3xl flex"
          style={{ borderBottom: 'none' }}
        >
          <div className="w-[50%] p-2 py-4 flex items-center">
            <MailOutlined className="text-[20px]" />
            <div className="text-[10px] ml-2">
              <div>anis@longemend.com</div>
              <div>elyia@longemend.com</div>
            </div>
          </div>
          <div
            className="w-[50%] border-l h-full p-2 py-4  flex items-center"
            style={{
              borderLeft: '1px solid #9c9c9c',
            }}
          >
            <PhoneOutlined className="text-[20px]" />
            <div className="text-[10px] ml-2">
              <div>+60389592593 (Anis/Elyia)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventVerificationCard;
