import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import { useCreateCheckoutIntentMutation } from '../../api/hooks/useCreateCheckoutIntentMutation';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripePaymentWrapper = ({ children, checkoutIntentData }) => {
  const appearance = {
    theme: 'stripe',
  };

  const {
    mutate: createCheckoutIntent,
    isLoading,
    data,
    isError,
  } = useCreateCheckoutIntentMutation();

  useEffect(() => {
    createCheckoutIntent(checkoutIntentData);
  }, [checkoutIntentData, createCheckoutIntent]);

  if (isError) {
    return <div className="mt-5">Something went wrong</div>;
  }

  if (isLoading) {
    return <div className="mt-5">Loading...</div>;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: data?.clientSecret,
        appearance,
      }}
    >
      {children}
    </Elements>
  );
};

export default StripePaymentWrapper;
