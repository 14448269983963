import axios from '../utils/axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createCheckoutIntent = async ({ data, token }) => {
  const response = await axios.post(
    BASE_URL + `/payment/create-checkout-intent`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  );

  return response.data;
};

export const paymentIntentDetails = async ({ data, token }) => {
  console.log('data in axios', data);
  const response = await axios.get(BASE_URL + `/payment/payment-details`, {
    params: data,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  return response.data;
};

export const allPaymentTickets = async ({ token }) => {
  const response = await axios.get(BASE_URL + `/payment/all-payment-tickets`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  return response.data;
};

export const paymentVerification = async ({ token, id }) => {
  const response = await axios.get(
    BASE_URL + `/payment/payment-verification/${id}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  );

  return response.data;
};

export const createFreeTicketRegistration = async ({ token, data }) => {
  const response = await axios.post(
    BASE_URL + `/payment/register-free-ticket`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  );

  return response.data;
};
