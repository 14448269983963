import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useUpdateEventPaymentMutation } from '../../api/hooks/useUpdateEventPaymentMutation';
import QRCode from 'react-qr-code';
import { useQuery } from 'react-query';
import { paymentIntentDetails } from '../../services/payment';
import { useAuth } from '../../hooks/useAuth';
import {
  UserOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  PushpinOutlined,
  MailOutlined,
  PhoneOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Spin, message } from 'antd';

const EventPaymentCallback = () => {
  const eventId = useParams().id;
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const paymentIntentId = searchParams.get('payment_intent');
  const isFree = searchParams.get('isFree');
  const paymentIntentClientSecret = searchParams.get(
    'payment_intent_client_secret',
  );

  const {
    data,
    isLoading,
    mutate: updateEventPayment,
  } = useUpdateEventPaymentMutation();

  const { data: paymentData, isLoading: paymentIsLoading } = useQuery(
    ['/payment-details', isFree, data?.paymentIntent?.id],
    () =>
      paymentIntentDetails({
        data: {
          isFree,
          intentId: isFree ? paymentIntentId : data?.paymentIntent?.id,
          intentSecret: isFree
            ? paymentIntentClientSecret
            : data?.paymentIntent?.client_secret,
        },
        token: user?.token,
      }),
    {
      onError: (error) => {
        console.log('Error: ', error);
        message.error('Payment is not successful. Please try again!');
        navigate('/register-event/' + eventId);
      },
      onSuccess: () => {
        message.success('Your ticket has been registered!');
      },
      retry: 0,
      enabled: !!data?.paymentIntent?.id || !!isFree,
    },
  );

  useEffect(() => {
    if (!isFree && paymentIntentId && paymentIntentClientSecret) {
      updateEventPayment({
        paymentIntentId,
        paymentIntentClientSecret,
        eventId,
      });
    }
  }, [
    paymentIntentId,
    isFree,
    paymentIntentClientSecret,
    updateEventPayment,
    eventId,
  ]);

  const detailedData = paymentData?.paymentDetails;

  if (isLoading || paymentIsLoading)
    return (
      <Spin spinning={isLoading || paymentIsLoading}>
        <div className="min-h-[60vh]"></div>
      </Spin>
    );

  return (
    <div className="main-container my-[100px] ">
      <div className="container">
        <div className=" px-[15%]">
          <h1>Ticket Summary</h1>
          <div className="w-full flex justify-center">
            <div className="border-[1px] border-solid border-gray rounded-xl max-w-[400px] bg-[#C8FCFF]">
              <div className="p-4">
                <div className="flex flex-col items-center">
                  <QRCode
                    size={50}
                    style={{
                      height: 'auto',
                      maxWidth: '120px',
                      width: '120px',
                    }}
                    value={
                      process.env.REACT_APP_FRONTEND_URL +
                      '/event-verification/?id=' +
                      detailedData?.id
                      // '?name=' +
                      // detailedData?.user?.firstName +
                      // detailedData?.user?.lastName +
                      // '&email=' +
                      // detailedData?.user?.email +
                      // '&eventName=' +
                      // detailedData?.event?.title +
                      // '&registrationDate=' +
                      // detailedData?.event?.createdAt +
                      // '&practicingNumber=' +
                      // detailedData?.EventRegistration?.practicingNumber
                    }
                  />
                </div>

                <br />
                <h2 className="text-center">{detailedData?.event?.title}</h2>
                <div className="text-left">
                  <div className="flex mt-4">
                    <div className="rounded-full p-[1px] border-[1px] border-solid border-gray">
                      <UserOutlined className="text-[18px]" />
                    </div>
                    <div className="ml-2 ">
                      {detailedData?.EventRegistration?.fullName}
                    </div>
                  </div>
                  <div className="flex mt-4">
                    <ClockCircleOutlined className="text-[20px]" />
                    <div className="ml-2">
                      {moment(detailedData?.event?.scheduleStart).format(
                        'h:mma',
                      )}{' '}
                      &nbsp;&nbsp;-&nbsp;&nbsp;
                      {moment(detailedData?.event?.scheduleEnd).format('h:mma')}
                    </div>
                  </div>
                  <div className="flex mt-4">
                    <CalendarOutlined className="text-[20px]" />
                    <div className="ml-2">
                      {moment(detailedData?.event?.scheduleStart).format(
                        'D MMMM, YYYY',
                      )}{' '}
                      &nbsp;&nbsp;-&nbsp;&nbsp;
                      {moment(detailedData?.event?.scheduleEnd).format(
                        'D MMMM, YYYY',
                      )}
                    </div>
                  </div>
                  <div className="flex mt-4">
                    <PushpinOutlined className="text-[20px]" />
                    <div className="ml-2">{detailedData?.event?.location}</div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              <div
                className="w-full border-[1px] border-solid border-[#9c9c9c] bg-[#f0f0f0] rounded-xl flex"
                style={{ borderBottom: 'none' }}
              >
                <div className="w-[50%] p-2 py-4 flex items-center">
                  <MailOutlined className="text-[20px]" />
                  <div className="text-[10px] ml-2">
                    <div>anis@longemend.com</div>
                    <div>elyia@longemend.com</div>
                  </div>
                </div>
                <div
                  className="w-[50%] border-l h-full p-2 py-4  flex items-center"
                  style={{
                    borderLeft: '1px solid #9c9c9c',
                  }}
                >
                  <PhoneOutlined className="text-[20px]" />
                  <div className="text-[10px] ml-2">
                    <div>+60389592593 (Anis/Elyia)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-end">
            <button
              className="button-color search-login-button border-none mt-10 text-white h-[35px] bg-[#165887] rounded-md flex items-center gap-2  px-9 py-[25px] cursor-pointer hover:bg-[rgb(68,95,216)] duration-300"
              onClick={() => {
                navigate('/dashboard?tab=Event-Tickets');
              }}
            >
              View in Profile <ArrowRightOutlined />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPaymentCallback;
